<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Sklad registratsiyasi</h3>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <div class="col-4 pl-0">
              <v-autocomplete
                :items="staff"
                v-model="combobox"
                :error-messages="fullNameErrors"
                item-text="full_name"
                item-value="id"
                label="Mas'ul nomi"
                dense
                outlined
                @input="$v.combobox.$touch()"
                @blur="$v.combobox.$touch()"
              ></v-autocomplete>
            </div>
            <div class="col-4">
              <v-text-field
                :error-messages="nameErrors"
                v-model="name"
                label="Sklad nomi"
                outlined
                dense
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
              ></v-text-field>
            </div>
            <div class="col-4 pr-0">
              <v-text-field
                :error-messages="codeErrors"
                counter="4"
                dense
                v-mask="'XXXX'"
                v-model="code"
                label="Kodi"
                outlined
                @input="$v.code.$touch()"
                @blur="$v.code.$touch()"
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <v-btn
                color="success"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
                :disabled="newSkladLoading"
              >
                <i v-if="newSkladLoading" class="el-icon-loading"></i> Saqlash
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import debounce from "debounce";
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      newSkladLoading: false,
      combobox: '',
      name: '',
      code: ''
    }
  },
  validations: {
    code: {
      required
    },
    combobox: {
      required
    },
    name: {
      required
    }
  },
  created() {
    this.$store.dispatch('getAllStaff')
  },
  computed: {
    staff() {
      return this.$store.state.requests.allStaff
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    fullNameErrors() {
      const errors = []
      if (!this.$v.combobox.$dirty) return errors

      !this.$v.combobox.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors

      !this.$v.name.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    codeErrors() {
      const errors = []
      if (!this.$v.code.$dirty) return errors

      !this.$v.code.required && errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Sklad registratsiyasi' }])
  },
  methods: {
    myFn(val) {
      // const data = {
      //   name: val
      // };
      this.$store.dispatch('getStaffList', val)
    },
    changed() {
      this.code = ''
      this.name = ''
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          name: this.name,
          code: this.code,
          responsible: this.combobox
        }
        this.newSkladLoading = true
        this.$store
          .dispatch('createSklad', data)
          .then(() => {
            this.newSkladLoading = false
            this.name = ''
            this.code = ''
            this.combobox = ''
            this.$v.$reset()
          })
          .catch((err) => {
            this.newSkladLoading = false
            console.error(err)
          })
      }
    }
  }
}
</script>
